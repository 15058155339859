@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500;600;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;1,500;1,600;1,700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;700&family=Signika:wght@300;400;500;600;700&display=swap');

:root {
  /* --dark: #002a33; */
--themedark: #fc9d2e;
--themelight:#fdd72a;
--themebold:#002a33;
--themeboldlight:#4d4e4e;
--blue:blue;
--overlay:#e13e3e85;
--white:#fff;
--black:#000;
--textopacity:0.85;
--textfaintopacity:0.75;
--green:#4CAF50;
--fav-unclick: transparent;
--fav-click:red;
--overlay1:#fc9d2eb0;
--shadow:.07843;
--normaltext_title: rgb(64 59 59);
--bborder:#ced4da;

--bggraylight:#edf2f7;

  --dark: #002a33;
  --bg:#fc9d2e ;
  /* --light: #f2f2f2; */
  --light: #feffff;
  --lightGrey: rgb(130, 134, 138);
  --accent: #fc9d2e;
  --cursive: 'Dancing Script', cursive;
  /* --font: 'Kumbh Sans', sans-serif; */
  --font: 'Poppins', sans-serif;
  --circle:linear-gradient(to right, #fdd72a 0%, #fc9d2e 50%, #fc9d2e 100%);
  --gradientt:gradient(to right, #fdd72a 0%, #fc9d2e 50%, #fc9d2e 100%)
}

* {
  /* font-family: 'Signika', sans-serif; */
  /* font-family: 'Kumbh Sans', sans-serif; */
  font-family: 'Poppins', sans-serif;
letter-spacing: 0.1px;
}

body{
  color:var(--themebold) !important;
  background-color: var(--white) !important;
  position: relative;
  
}
.home_background{
  background-image: url('../Images/homebg.jpeg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    
}


.banner-section {
  background-image: url('../Images/Banner.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.logo{
  margin-left:40px;
  height:70px;
}

.bannerc .slider-wrapper{
height: 800px;
}
.bannerc .carousel .slide img {
  width: 100%;
height: 100%;
 }

.navbar{
 
    padding: 0;
    background-color: transparent;
    width: 100%;
    position: fixed;
    top: 0;
    z-index:120;

    color: white;
}

.is-sticky {
  box-shadow: 0px 1px 2px 1px #e6dcdc;
  
  background-color: #fff;
}
/*
.navbar{
 padding:0px;
  background-color:transparent;
  width:100%;
  position: fixed;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0.6),rgba(255, 255, 255, 0.75),rgb(255, 255, 255,0.85), rgb(255, 255, 255,0.90), rgba(255, 255, 255, 0.95),rgb(255, 255, 255));
 z-index: 120;
 
}*/
.is-sticky .navbar{
  background-color: #fff;
  box-shadow: 0px 1px 2px 1px #e6dcdc;

}
.navbar-light .navbar-nav .nav-link {
  color:rgba(0,0,0,.8);
 }

 .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0,0,0,.7);
}

.navbar:hover{
  /*background-image: linear-gradient(to top, rgba(255, 255, 255, 0.3),rgba(255, 255, 255, 0.80),rgba(255, 255, 255, 0.9),rgb(255, 255, 255,0.92), rgb(255, 255, 255,0.95), rgba(255, 255, 255, 0.98),rgb(255, 255, 255));
*/
}
  .navbar-nav{
  margin: auto;

}
.drp-menu-item{
  background-color:transparent;

}
.btn-link{
  background-color:transparent;
}
.navbar-light .navbar-toggler{
  /* color: rgba(0,0,0,.5); */
  /* border-color: rgba(0,0,0,.1); */
  outline: none;
  border: none;

}
.navbar-collapse{
  text-align:center;

}

/* .navbar-collapse .show{
  background: var(--white);
} */

/* .navbar {
  display: grid;
  align-items: center;

  background-color: transparent;
  grid-template-columns: 0.1fr 0.6fr 0.2fr;
  width: 100%;
  text-align: center;
  justify-content: center;
  opacity: 0.8;
}
.nav-items ul {
  list-style-type: none;
  background-color: transparent;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
}
.nav-items ul li {
  display: inline;
}
.nav-items ul li a {
  text-decoration: none;
  font-size: small;
  color: var(--dark);
} */
.section2{
  margin-left: 100px;
  margin-right: 100px;
  margin-top:50px;
  margin-bottom: auto;
  display: grid;
  align-items: center;
}

.search-container {
  /* background-color: var(--light); */
  background-color: var(--white);
  /* margin: 0 100px; */
  /* height: 75px; */
  height: auto;
  padding: 20px;
  display: grid;
  display: grid;
  align-items: center;
  position: relative;
  top: -40px;
  box-shadow: 1px 2px 20px 0 rgba(252,157,46,0.4);
  border-radius: 20px;
}

/* .search-container Form {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
} */
.form-place {
  border: none;
  border-bottom: 1px solid var(--bborder);
  padding: 6px;
}

.searchBtn div {
  /* background-color: var(--dark); */
  background-color: var(--bg);
  padding: 8px 20px;
  transition: 0.2s linear;
  border-radius:20px;
}
.searchBtn {
  color: var(--light);

  text-decoration: none;
  font-weight: 200;
    border-radius:20px;
}

.btnGradient div{
  background: linear-gradient(to right, #fa9e1b, #FF5722, #F44336);
  padding: 8px 20px;
  transition: 0.2s linear;
  border-radius:20px;
}
.btnGradient{
  /* background: linear-gradient(to right, #fa9e1b, #FF5722, #F44336); */

  text-decoration: none;
  font-weight: 200;
    border-radius:20px;
    color: var(--light);
}
/* .btnGradient:hover{
  background: linear-gradient(to right,  #FF5722, #F44336, #fa9e1b);
  padding: 8px 20px;
 color:#fff;
  border-radius:20px;
} */
.green{
  color: rgba(53,53,53,1);
}
.red{
  color:red;
}
.h2-text-event-details{
margin-left: -10px;
margin-right: -10px;

}
.destdiv{
  overflow: hidden;
  position: relative;
  align-content: center;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
    box-shadow: 3px 4px 10px 2px rgb(38 38 38 / 20%);
}
.destdiv h2{
  font-size: 1.3rem;
  font-weight:700;
  position: absolute;
  color: rgba(255,255,255,1);
text-shadow: #888;
  z-index: 13;
}
.destdiv:hover .Destinations {
  transform: scale(1.1);
}

.Destinations {
  border-radius: 12px;
height: 100%;
width: 100%;
 transition: 0.4s;
 z-index: 10;
 opacity: 1.8;
}
.Destinations:hover {
 box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.3);
 cursor: pointer;
}
.Destinations::after {
 content: '';


 width: 100%;
 height: 100%;
 opacity: 0.3;
 z-index: 1;
 /* border-radius: 8px; */
}
.Destinations h2 {
 color: var(--light);

 font-weight: 600;
 position: relative;
 z-index: 5;
}


.destination-heading-wrapper::before {
  content: 'featured';
  font-family: 'Dancing Script', cursive;
  font-size: 100px;
  position: absolute;
  left: 50%;
  font-weight: 700;
  transform: translate(-50%, -50px) rotate(-5deg);
  color: var(--light);
  opacity: 0.8;
  z-index: -20;
}

.destination-heading {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
}

.small-heading {
  font-size: 22px;
  font-weight: 400;
  font-family: var(--cursive);
  color: var(--accent);
  margin: 0px 0;
}

.big-heading {
  font-size: 40px;
  margin: 0;
  font-weight: 700;
  color: var(--themebold);
  line-height: 75px;
  font-family: 'Kumbh Sans', sans-serif;
}
.destinations-cards-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 50px 200px;
  grid-gap: 30px;
  align-content: center;
}
.destination-card {
  position: relative;
  top: 0;
  transition: 0.5s;
}

.destination-card::after {
  content: '';
  position: absolute;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0)
  );
  width: 100%;
  height: 60%;
  z-index: 2;
  bottom: 5px;
  left: 0;
}

.arrow-right img {
  position: relative;
  left: 0;
  transition: 0.6s;
}

.adventure-cards-wrapper {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 5px;
  padding: 10px 200px;
}
.adventure-heading-wrapper .adventure-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0 25px 0;
}

.adventure-card {
  position: relative;
  transition: 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-event-details{
font-size:25px;
font-weight:300;
}
.event-font{
  font-size:18px;
  font-weight:300;
}
.event-font-size{
  font-size:18px;
}

.booking-car-row{
  margin-bottom:15px;
}
.top-events-cards-wrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 50px;
  grid-gap: 15px;
  height: 75vh;
}
.top-event-item1 {
  grid-row: 1/3;
  grid-column: 2/3;
}
.top-event-card {
  display: flex;
  align-items: flex-end;
  position: relative;
  transition: 0.8s;
}
.top-event-card:hover {
  cursor: pointer;
  box-shadow: 6px 12px 30px 0 rgba(0, 0, 0, 0.4);
}

.top-event-card:hover .top-event-card-text h3 {
  color: var(--accent);
}


.top-operators {
  background-color: var(--dark);
  padding: 50px;
  margin-top: 100px;
}
.operator-heading {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.operator-heading h1 {


}
.email-subscribe{
  height: 100%;
  max-width: 600px;
  position: absolute;
  right: 20px;
  display:flex;
}
.email-subscribe input {
 height: 50px;
 width: 70%;
  color: var(--themelight);
  font-size: 16px;
  
}
.email-subscribe .input {
  background-color: transparent;
  padding: 10px;
  border: 2px solid var(--light);
  color: var(--light);
  border-radius: 12px;
  max-width: 500px;
}
.email-subscribe .submit {
  background-color: var(--gray-dark);
  opacity: 0.9;
  border: 1px solid var(--accent);
  max-width: 110px;
  height: 50px;
  margin-left: 10px;
  border-radius: 12px;
  color: var(--white);
}

.email-subscribe .submit:hover{
  opacity: 1.1;
  background-color: var(--gray-dark);
  box-shadow: none;

}
.email-subscribe .submit:active{
  opacity: 1.1;
  background-color: var(--gray-dark) !important;
  box-shadow: none;
}
.email-subscribe .submit:focus {
  opacity: 1.1;
  background-color: var(--gray-dark);
  border-color:var(--gray-dark);
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
/* .email-subscribe .submit:focus{
  opacity: 1.1;
  background-color: var(--gray-dark);

} */
/* .top-destinations {
} */
.top-destinations h1 {
}
.top-destinations-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  padding: 0 200px;
}
.adventure-events h1 {
  /* margin: 20px 0 20px 200px; */

 
 
}
.bookingdetail .card2, .bookingdetail .adventure-show-more{
  z-index: 1;
position: relative;
}

.bookingdetail .Card-style,footer{
  position: relative;
  z-index: 1;
}
.newsletter{
  z-index: 2;
width: 100%;
background-color: #fcac50;
max-width: 1100px;
padding: 20px;
vertical-align: middle;
margin: 0 auto;
align-items: center;
position: relative;
bottom: -43px;
box-shadow: 1px 2px 20px 0 rgba(109, 108, 108, 0.4);
border-radius: 20px;
height: 100%;
}
.newsletter-text h1{
color: var(--white);}
.adventure-events {
  margin-bottom: 50px;
}
.adventure-events-grid {
  display: grid;
  /* grid-template-columns: repeat(5, 1fr); */
  padding: 0 200px;
  gap: 25px;


}
.adventure-show-more{
  text-align: center;
 margin: auto;
}
.navigation{
  display:block;

}

.layer2{
  background: var(--black);
  height: 75vh;
  z-index: 2;
  position: relative;
}

.view-event-title{
  justify-content: center;
    /* background: var(--black);
    opacity: 0.5;
    height: 80vh; */
    color:var(--light);
    display:flex;
    height: 75vh;
}
.view-event-title h3{
  color:var(--light);
  font-size: 40px;
  font-family: 'Dancing Script', cursive;
}

.pagination{
  display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}
.gridlist{
  width:100%;
  height: 100%;
  overflow: hidden;
  padding: 10px;
}
.rootclass{
  display: 'flex';
    flexWrap: 'wrap';
    justifyContent: 'space-around';
    overflow: 'hidden';
     padding: 0 200px;
}
.adventure-col{
  flex: 50%;
  padding: 0 4px
}

.adventureEvent-container:hover {
  cursor: pointer;
}
.AdventureEvent-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.AdventureEvent-text h1,
.AdventureEvent-text h4 {
  margin: 2px 0;
}
.AdventureEvent-text h1 {
  color: var(--dark);


}
.AdventureEvent-text h4 {
  font-weight: 400;
  font-size: 14px;
  color: var(--lightGrey);
}

footer {
   background-color: #fcac50;
  /*background-image: url('../Images/Footer.png');*/
 /* background-image: linear-gradient(to bottom, rgba(252, 157, 46, 0),rgba(252, 157, 46, 0.65),rgba(252, 157, 46, 0.70),rgba(252, 157, 46,0.75), rgba(252, 157, 46,0.85), rgba(252, 157, 46, 0.90),rgba(252, 157, 46,0.95));
*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(100px,1fr));
  /* grid-template-columns: repeat(5, minmax(120px,1fr)); */
  padding: 160px 0px 90px 0px;
  grid-gap: 80px;
}

footer .footer-heading {
  color: var(--gray-dark);
  font-weight: 600;
}
footer .footer-subtitle {
  /* color: #808080; */
  color:var(--themebold);
  font-weight: 200;
}
footer .copyright-logo {
  display: flex;
  align-items: flex-start;
  margin-top: 25px;
}
footer .copyright-logo h5 {
  /* color: #808080; */
  color:var(--themebold);
  font-weight: 200;
}
footer .footer-list {
  list-style: none;
  padding: 0;
  color: var(--themeboldlight);
 
}
footer .footer-list li {
  padding: 0 0 5pt 0;
  color:var(--gray-dark);
  font-size: smaller;
}
footer .footer-list li a {
  text-decoration: none;
  /* color: #808080; */
  color:var(--gray-dark);
  transition: 0.1s;
}
 
footer .footer-list li a:hover {
  color:var(--themebold);
}


.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: var(--white);
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.content-poppup{
  background: var(--white);
  padding: 30px;
  /* overflow: scroll; */
}
.popup-overlay{
  background: #000000bf;
  max-width: 700px;
  overflow-y: scroll;
  margin: auto;
  height:450px

}
.popup-overlay::before{

}
.card_body{
  width: 100%;
padding-left: 15px;
padding-right: 15px;
padding-top: 5px;
}
.card2{
  overflow:hidden;
  box-shadow: 8px 8px 16px -6px rgb(221 218 214 / 20%);
  /*box-shadow: 0.5px 1px 1px 1px rgba(252,157,46,0.2);*/
  border:none;
}
.card2 .Title-all{
  color: rgba(53,53,53,var(--textopacity));
}
.card2 .price{
  color: #fff;
  float: right;
  /* font-size: 14px; */
  font-weight: 600;
  background-color: var(--themedark);
  padding: 10px;
  border-radius: 30px;
  position: relative;
}
.card2 .sub-title-all{
  color: rgba(53,53,53,var(--textfaintopacity));
  font-size: 12px;
}
.card2:hover {
    transition: all 0.4s ease-out;
    box-shadow: 0.6px 1.1px 1.1px 1.1px rgba(252,157,46,0.3);
            /*top: -4px;*/
            top: -2px;
    background-color:var(--white );
  }
  .card2:hover img{

 /*transform: scale(1.05);*/
  }
  .card-titlle{
    font-weight:'bold'
  }

  .whyus{
    text-align: center;
    font-weight: bold;
    font-size: 35px;
  }
  .whyusText h2{

font-weight: bold;
text-align: center;
padding-top: 20px;
margin:auto;
  }
  .whyusText p{
    text-align: center;
  }
  .customer-review-btn{
    float: right;
  }
  .customer-review-btn:hover{
    text-decoration: underline;
    color:var(--themedark);
  }
  .favorite{
    position: sticky;
    margin-top: -30px;
    float: right;
    z-index: 1;
    background: var(--white);
    border-radius: 50%;
    padding: 20px;
    border: 1px solid #c7d0d9;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14);
    margin-right: 20px;
  }
  .payment-cancellation{
    color:var(--blue)

  }
  .payment-cancellation:hover{
    text-decoration: underline;
    color:var(--blue);
  }
  .operator-title{
    text-align: center;
    margin-top: 50px;
  }

  .operator-titleh2{
    text-align:center;
    margin:auto;

  }
.op-logo{
  background: var(--white);
  position: absolute;
  top: -30px;
  border-radius: 10px;
  /* margin: auto; */
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #bfaeae;
}
.review-total-font{
  font-size:12px;
}
.border-homepage-input{
  border-bottom: 1px solid var(--lightGrey) !important ;
}
.MuiInput-underline::before{
  border-bottom: 1px solid var(--lightGrey) !important;
}


.image {
  display: block;
  width: 100%;
  height: 190px;

}

.overlay {
  /* position: absolute;
  top: 0;
  bottom: 0;

  height: 100%;

  width:227px;
  opacity: 0;
  transition: .5s ease;
  background-color:var(--overlay); */

  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}
/* .container {
  position: relative;
  width: 50%;
}
.container:hover .overlay {
  opacity: 1;
} */

.text {
  color: var(--white);
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.topop-logo {
  background: var(--white);
  /* position: absolute; */
  /* top: -30px; */
  width: 40%;
  height: 110px;
  border-radius: 50%;

  margin-top: -100px;
  margin-bottom:20px;
  /* margin: auto; */
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  /* border-top: 3px solid #e32828; */
  box-shadow: 1px 2px 20px 0 rgb(223 40 40 / 19%);
  /* border: 0px solid #bfaeae; */
}
.stars{
  margin-left:50px !important;

}
.star li{
  margin-right: 10px;
}




/*Operator details page*/
/* .banner {
  width: 100%;
  background: url(images/background4.jpg) no-repeat;
  background-size: cover;
  height: 250px;
  position: relative;
  z-index: 75;
} */
.content {
  position: relative;
  z-index: 10;
}
.circle-border {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  background: var(--circle);
  position: absolute;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  z-index: 50;
  bottom:-219px;
}
.circle-image {
  width: 200px;
  height: 200px;
  background-color: var(--white);
  position: absolute;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  border-radius: 50%;
  z-index: 100;
  bottom: -191px;
}
.load-more{
  margin-left: 40% !important;

    margin-right: auto !important;

}

.Title-all{
  color:var(--white);
}

.sub-title-all{
  color:var(--white);
}

.btncommon{
  text-align: center;

  text-decoration: none;
  font-weight: 500;
  color: var(--white);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border: none;
    padding:initial;
  transition: 0.5s;
}
.btncommon:hover {
  background-position: right center;
}
.btnrounded{
  background-size: 200% auto;

  border-radius: 6px;
  padding: 1em 2em;
  background-image: linear-gradient(to right, #fdd72a 0%, #fc9d2e 50%, #fc9d2e 100%)
  
}
.width{
  background-size: 200% auto;

    border-radius: 6px;
    padding: 1em 2em;
    width:100%;
    /* background-image: linear-gradient(to right, #fdd72a 0%, #fc9d2e 50%, #fc9d2e 100%); */
    background: transparent;
    color: #000;
    border: 1px solid var(--themedark);
    box-shadow:none;
}
.width:hover{
  /* background-image: linear-gradient(to right, #fdd72a 0%, #fc9d2e 50%, #fc9d2e 100%); */
  background-color: #fc9c2ef3;
  border: unset;
  color:#fff !important;
}
.width:focus{
  background: transparent;
 color: #000;
  border: 1px solid var(--themedark);

}



.btncircle{
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background-image: radial-gradient(#fdd72a,#fc9d2e);

}
.btncircle:hover{
  background-image: radial-gradient(#fc9d2e, #fdd72a);
}
.btnrounded2{
  background-size: 200% auto;
  border-radius: 6px;
   padding: 0px;
  background-image: linear-gradient(to right, #fdd72a 0%, #fc9d2e 50%, #fc9d2e 100%);
  width: 40%;
}



.inner-fav-div{
  margin-right: 15px;
    float: right;
    margin-top: -190px;
}

.inner-fav-div2{
  margin-right: 15px;
    float: right;
    margin-top: -140px;
}
.fav-unclick{
color:var(--lightGrey);
}
.fav-click{
color:var(--fav-click);
}
.card_body{
  /*padding-left:10px;*/
  position: absolute;
  bottom: -4px;
  z-index: 11;
  padding-bottom: unset;
}
.margin-btm{
  margin-bottom:20px;
}
.font-style {
  text-transform: capitalize;
  font-weight: 500;
}
.overlay1{
  position: relative;
  top: -40px;
  overflow: hidden;
  background-color: var(--overlay1);
  color: var(--white);
  height: 40px;
  bottom: 0px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  /* margin: auto; */
  padding: 10px;
  margin: auto;
}


.login-btn{
  text-align: center;
  border: none;
  background-color: transparent;
  text-decoration: none;
  font-weight: 500;
  color: var(--black);
  padding: 10px;
  border-radius: 1px;
}
.login-btn:hover{
  background-color: var(--themedark);
  color:var(--white) ;
  text-decoration:none;
}
.bookBtn-wrapper1 a {
  color: var(--black);
  text-decoration: none;
}


.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{
  color:var(--white);
  background-color: var(--themedark);
  border-radius: 12px;
}
.navbar h1 svg:hover {
  fill: var(--themedark);
  ;
}
.signup-btn{
  text-align: center;
  border: none;
  background-color: transparent;
  text-decoration: none;
  font-weight: 500;
  color: var(--black);
  padding: 10px;
}
.signup-btn:hover{
  background-color: var(--themedark);
  color:var(--white);
  text-decoration:none;
}

.featured-card{
  height:150px;
  border-radius:12px;
   margin-bottom:30px;
   box-shadow: 3px 4px 10px 2px rgb(38 38 38 / 20%);

}
.featured-card .price-dis{
  /*color: rgba(53,53,53,var(--textopacity));*/
  color: #fff;
  font-weight: 600;
float: right;
padding: 10px;

}


.featured-card .price{
  /*color: rgba(53,53,53,var(--textopacity));*/
  color: #fff;
  font-weight: 600;
float: right;
background-color: var(--themedark);
padding: 10px;
border-radius: 30px;
}
.featured-card:hover{
transition: all 0.2s ease-out;
    box-shadow: 0px 3px 6px rgba(38, 38, 38, 0.2);
    top: -2px;
    border: 1px solid #cccccc;
    background-color:var(--white );
}
.card-img-featured{
  height:150px;
   border-radius: 12px 12px 0px 0px;
}
.eventopacity:not(.review){
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(30, 30, 30));
    height:150px;
    width: 100%;
    border-radius:12px;
    background-size: cover;
    position:absolute;
   bottom: 0px;
   z-index: 10;
}


/* .h5{
font-size: 1.1rem;
} */
h2{
  font-size: 1.6rem;
  color: rgba(53,53,53,var(--textopacity));
  font-weight: 500;
}

.normaltext{
color: rgba(53,53,53,var(--textfaintopacity));
    font-size: 1rem;
    font-weight: 400;
}
h3{
  color: rgba(53,53,53,var(--textfaintopacity));
  /* font-size: 1.2rem;
  font-weight: 300; */
  font-size: 1rem;
    font-weight: 400;
}
h4{
  color: rgba(53,53,53,1);
font-size:1rem ;

}

h5{
  color: rgba(53,53,53,1);
font-size:0.87rem ;

}
h1{
  font-size: 2rem;
  color: rgba(53,53,53,var(--textopacity));
  font-weight: 500;

}
p{
  color: rgba(53,53,53,1);
  font-size:.875rem ;
      white-space: pre-wrap;
}
.image_border{
border-radius: 12px;  
}
.image_border1{
 
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  }

.carousel_height{
  height:80vh;
}
.banner_color{
  position: relative;
  color:var(--light);
  margin:auto;
  text-align: center;
  z-index: 10;
  font-size: 56px;
  font-weight: 700;
}
.card_body{
border-radius: 12px;  
}
.Card-style{
  box-shadow: 0 3px 12px rgba(0,0,0,var(--shadow));
  border:0px;
  padding:15px;
  border-radius: 12px;
  
  
}
.verify_email{
      position: absolute;
    right: 10px;
    top: 115px;
    text-decoration: underline;
    cursor:pointer;
}
.Card-style .card, .Card-style .card-body{
  width: 100%;
}
.Card-style2{

  width:100%;
}
.Margin-20{
 margin-top:20px;
}

.booknow{
  position: absolute;
  bottom: 0;
  left: 0px;
    padding: 30px;

}

.booknow-width{
  width: 100%;
}
.icon-style{
  margin-right:10px;
  font-size: 1rem;
    margin-top: -6px;
    color: rgba(53,53,53,var(--textfaintopacity));
    font-weight: 600;
}
.h3_sub-title h3{
  padding-left: 10px;
}

.normaltext_title{
  color: var(--normaltext_title);
    font-size: .875rem;
}

.tags{
  color: rgba(53,53,53,var(--textopacity));
 
  background-color: var(--bggraylight);
  padding: 10px;
  border-radius: 12px;
  font-size: .875rem;
  /* margin-left:10px; */

}
.card-headers{
 
  background-color: #fff;
  margin-bottom: 20px !important;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 12px !important;
}
.toogle-card{
  border:0px;
}
.chevron{
  float: right;
}
.book_btn{
  background-size: 200% auto;
    border-radius: 6px;
    /* padding: 1em 3em; */
    background: var(--white);
    color: var(--themebold);
    border: 1px solid var(--lightGrey);
    display: block;
      width: 100%;

}
.book_btn:hover{
  background-color: var(--themelight);
}
.para_description{
  padding-left: 30px;
}
.nav-head{
  padding-top: 0px;
}
.nav_link{
  margin:auto;
}
.collapse_navbar{
  display: contents;
}
.profileImg{
  position: absolute;
 
    margin: 0 auto;
    top: -75px;
    box-shadow: 0 3px 12px rgba(0,0,0,var(--shadow));
    left: calc(50% - 75px);
    
    border: 1px solid;
    border-color: rgb(124, 121, 121);
}
.user_profile_icon{
  border-radius:50%;

}
.profile-tb_operator {
  color: rgba(53,53,53,var(--textfaintopacity));
  font-weight: 300;
  margin-top: 50px;
  text-align: center;
}

.operator_banner{
  background-image: url('../Images/register.jpg');
}

.profile-tb{
  color: rgba(53,53,53,var(--textfaintopacity));
  font-weight: 300;
  margin-top: 80px;
  text-align: center;

}
.profile-tb-icon{
  color: rgba(53,53,53,var(--textfaintopacity));
  font-size: 1.6rem;
  font-weight: 300;

}
.ub-boooking{
margin-bottom:5px;
}
.ub-heading{
  padding-top:10px;
}
.ub_rating{
  margin-top: -15px;
  margin-bottom: 0px;
}
.ub-row{
  margin-top:50px;
}
.ub_stars_row{
  margin-top: -10px;
}
.card-style-padding{
  padding:0px;
   margin-right:20px;
}
.btn.focus, .btn:focus {
     outline: none; 
    box-shadow: none;
}
.common-margin{
  margin-top:50px;
  margin-bottom: 50px;
}
.menu{
  color: rgba(53,53,53,var(--textfaintopacity));
  font-size:1rem;
}
.menu:hover{
  color: rgba(53,53,53,var(--textfaintopacity));
}
.star-wishlist{
  margin-left:1px;
}

.topoperator ul
{
  margin-bottom: 10px;
}
.topoperator .card_body{
 bottom:20px;
}

/*price*/
.slider {
  margin-bottom: 40px;
}

.slider label {
  color: #888;
  font-size: 0.8rem;
  margin-bottom: 20px;
  display: block;
  text-transform: capitalize;
}

.input-range__slider {
  background:var(--themedark);
  border: none;
}
.price-input{
  width:95%;
}
.input-range__track {
  background: #ddd;
}

.input-range__track--active {
  background: var(--themedark);
}

.input-range__label--value .input-range__label-container {
  background:var(--themelight);
  color: var(--white);
  font-size: 0.65rem;
  padding: 2px 5px;
  border-radius: 2px;
}

.input-range__label--min .input-range__label-container,
.input-range__label--max .input-range__label-container
 {
  font-size: 0.65rem;
  color: var(--lightGrey);
  left: 0;
}

.input-range__label--max .input-range__label-container {
  left: 81%;
}
/* <.............Why us?............> */
.why-us-img{
  width:50%;
  margin:auto;
  height:150px;
}
@media (max-width:1500px) {
  .bannerc .slider-wrapper{
    height: 600px;
    }
}

@media (max-width:1200px) {
  .bannerc .slider-wrapper{
    height: 500px;
    }
}

@media (max-width:900px) and (min-width:768px) {
  .logo{
    margin-left:40px;
    height:70px;
  }
  .navbar-light .navbar-toggler{
    text-align: center;
  }
  .see_all_link{
    font-size: 0.875rem;
   text-decoration: underline;
   position: absolute;
    right: 40px;
    top: 1px;
   }
}
 @media (max-width:900px) and (min-width:560px) {
  .bannerc .slider-wrapper{
    height: 400px;
    }
  .Footer-Style{
    margin:30px 100px 0px 100px !important ;
  }
  .input-range__label--max .input-range__label-container {
    left: 90%;
}
.verify_email {
    position: unset;
    right: 10px;
    top: 115px;
    text-decoration: underline;
    cursor:pointer;
}
 }
 @media (max-width:700px){
  .bannerc .slider-wrapper{
    height: 300px;
    }
  }

@media (max-width:560px){
  .bannerc .slider-wrapper{
    height: 250px;
    }
.Footer-Style{
  margin: 30px 30px 0px 30px !important;
}
.ub-font{
  color:var(--white);
  font-weight: bold;
}
.mb-style{
padding-left: 20px;
}
.input-range__label--max .input-range__label-container {
  left: 90%;
}
} 




.minus-btn{
  font-size: 1.5rem;
  color:var(--themedark);
  margin-left:10px;
  margin-right: 10px;
}



.star{
margin-right: 5px;  
}
.carousel-height{
  height:565px;
}
.faq{
  margin-left: 55px;
}
.padding-trip{
  padding-left:15px;
}
.paybtn{
  float:right;
}
.btn-pay{
  width:100%;
}
 
.padding-home{
  padding-top: 50px;
}



.card-container {
  display: flex;
  flex-direction: column;
  width:100%;
  /* height: 463px; */
  border: 1px solid #E2E3E5;
  border-radius: 12px;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 18px;
  position: relative;
}

/* .hero {
  height: 40%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-image: url("https://pbs.twimg.com/media/Egi2uv5UcAA4txa?format=jpg&name=small");
  background-size: cover;
} */

.avatar {
  /* border: 5px solid white;
  width: 86px;
  height: 86px;
  background: navy;
  border-radius: 50%;
  position: absolute;
  top: 120px;
  left: 30px;
  background-image: url("https://pbs.twimg.com/profile_images/1317649872191778816/d2vShRiA_reasonably_small.jpg");
  background-size: cover; */
}

.verified {
  width: 27px;
  height: 27px;
  fill: #4F9DE8;
  color: #4F9DE8;
  display: inline-block;
  margin-left: -2px;
  vertical-align: middle;
}
.handle-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 50px 20px 0 20px;
  align-items: center;
}

.name-container {
  display: flex;
  flex-direction: column;
}

.name-container h1 {
  font-weight: 500;
  font-size: 28px;
  color: #18181A;
}

.name-container p {
  font-size: 22px;
  font-weight: 400;
  margin-top: -15px;
  color: #6A737B;
}
.search-container .searchform{
  width: auto;
}
.search-container{
}

.search-container .btncommon{
  margin-right: 15px;
}

button {
  width: 170px;
  height: 64px;
  border-radius: 32px;
  color: #4F9DE8;
  font-weight: 500;
  font-size: 24px;
  border: 2px solid #4F9DE8;
  background-color: white;
}

.blurb {
  margin: 0 20px 0px 20px;
  /* color: #161618;
  font-size: 22px; */
  font-weight: 400;
}
.Title-all1{
  color: rgba(53,53,53,var(--textopacity));
}


/*Review section*/
.review{
  margin-bottom: 30px;
}
.reviewer{
 
  padding-left: 10px;
  padding-right: 10px;
 cursor:default;
display: block;
position: relative;
 color: var(--white);
  opacity: 0.92;
  text-transform: capitalize;
  z-index: 15;
  line-height: 1.2;
  top: 50%;
  transform: translateY(-50%);
}
.reviewstars ul{
  justify-content: center;
}
.review .featured-card:hover{
  top:0;
  box-shadow: initial;
}

.review .featured-card:hover .reviewer{
 
  
  opacity: 1;
}
.review .featured-card:hover img{
  
 opacity: 0.6;
}
.review .fa-quote-left,.review .fa-quote-right{
font-size: 2em;
display: block;
color: var(--themedark);
}
.review .fa-quote-left{
  padding-top: 20px;

}
.review .fa-quote-right{
  padding-top: 5px;
}
.review .react-multiple-carousel__arrow
{
}
.review .featured-card{
  background-color: #000;
  text-align: center;
  margin-bottom: 0px;
  transition-duration: 15s;
  transition-property: opacity;
  transition-delay: 2ms;
  box-shadow: 3px 4px 10px 2px rgb(38 38 38 / 20%);
}
.review .carousel-item-padding-40-px{
padding-left: 10px;
padding-right: 10px;
}
button:focus, input:focus {
  outline: none;
}
.form-control:focus{
  border-color: none;
outline: none;
box-shadow: none;
}
.review .react-multi-carousel-list button{
  width: unset;
  height: unset;
  background-color: unset;
}
.review .react-multi-carousel-list button:hover{
 background-color: var(--themedark);
}
.review .react-multiple-carousel__arrow--right{
  right: 1px;
}
.review img{
  border-radius: 12px;
  opacity: 0.7;
  height: 100%;
  width: 100%;
}
.review .react-multiple-carousel__arrow--left{
  left: 1px;
}
.review .featured-card{
  border: 2px solid var(--themedark);
}

.reviewer h4{
margin: unset;
color: var(--white);
}
.reviewer p{
  margin: unset;
  color: var(--bggraylight);

}  

.reviewstars{
  z-index: 15;
 text-align: center;
 display: inline;
}
.review .Title-all{
  /*margin-top: 20px;*/
  font-size: 1.3rem;
  margin-left: 15px;
  margin-right: 15px;

}

.reviewTitle{
  /*margin-top: 20px;*/
  font-size: 1.3rem;
  margin-left: 15px;
  margin-right: 15px;

}
/*
.review .eventopacity{
  background-image: none;
 /* background-color: var(--themedark);
    height:150px;
    border-bottom-left-radius:8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius:unset;
  border-top-right-radius: unset;
   z-index: 10;
   opacity: 0.9;
}*/
.MuiAutocomplete-endAdornment {
  top: calc(-90% - 14px) !important;
  right: 0;
  position: absolute;
  left: 130px;
  display:none;
}
.MuiIconButton-root{
  margin-left: 80px;
    width: 20px;
    cursor: pointer;
}
.filters-all{
    margin-bottom: 20px;
}

.card-details_mobile{
  height:565px;
  
}
.viewbtn{
  margin: auto;
  text-align: center;
}
 .change-date{
  width: 50%;

  margin: auto;

 }
 @media screen and (max-width:991px){
  .newsletter{
    height: 150px;

  }
  .newsletter-text h1 {
    
      font-size: 1.5em;
    }
    .navbar{
 
      box-shadow: 0px 1px 2px 1px #e6dcdc;

      background-color:white;
      width: 100%;
      position: relative;
      top: 0;
  z-index: 120;
      color: white;
  }
  .is-sticky{
      box-shadow: 0px 1px 2px 1px #e6dcdc;
      
      background-color: #fff;
    
  }
.email-subscribe{
  height: 120px;
  max-width: 600px;
  position: absolute;
  right: unset;
  margin-top: 10px;
}
 }
@media screen and (min-width: 320px) {
  .see_all_link{
    font-size: 0.875rem;
   text-decoration: underline;
   position: absolute;
    right: 40px;
    top: 1px;
   }
  .logo{
    margin-left:15px;
    height:40px;
  }
  .mr_15{
    margin-right:0px;
  
  }
  .bookBtn-wrapper1
  {
   width:100%;
  }
  .profileImage_mobile{
    margin-top:20px;
  }
  .subscribe-message{
    color: #fff;
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 20px;
  
  }
  .btn_mobile{
    margin-bottom:15px;
  }
  
  .inner-menu{
    position: absolute;
    right: 15px;
    top: -5px;
    left: 90%;
   }

  .filter-mobile{
    top: calc(20% - 14px);
    right: 0;
    position: absolute;
    left: 80%;
}
.card-details_mobile{
  height:auto;
  
}
.book_btn{
  width:100%;
  /* padding: 1em 3em; */
}
.btnrounded {
   padding-left: 10px;
   padding-right:10px;
height: 55px;
  /* width: 130px; */
}
.input-range__label{
  left:-4px;
}
}
@media (max-width:420px) and (min-width:300px) {
  .logo{
    margin-left:15px;
    height:40px;
  }
  h1{
    font-size:1.4rem;
  }
  h2{
    font-size:1.2rem;
  }
  .newsletter-text h1 {
    font-size: 1.4em;
}
.margin-userbooking{
  margin-right: 10px;
}
/* .navbar-light .navbar-toggler{
  text-align: right;
} */
}
@media (max-width:576px) and (min-width:200px) {
  .navbar-light .navbar-toggler{
    text-align: right;
  }
  .see_all_link{
    font-size: 0.875rem;
   text-decoration: underline;
   position: absolute;
    right: 40px;
    top: 1px;
   }
   .verify_email {
    position: unset;
    right: 10px;
    top: 115px;
    text-decoration: underline;
    cursor:pointer;
}
}
 
@media screen and (min-width: 420px) {
  .filter-mobile{
    top: calc(20% - 14px);
    right: 0;
    position: absolute;
    left: 80%;
}
.card-details_mobile{
  height:auto;
  
}
.mr_15{
  margin-right:15px;

}
.book_btn{
  width:100%;
  /* padding: 1em 3em; */
}
}

@media screen and (min-width: 720px) {
  .mr_15{
    margin-right:15px;
  
  }
  .filter-mobile{
    top: calc(20% - 14px);
    right: 0;
    position: absolute;
    left: 80%;
}
.card-details_mobile{
  height:auto;
  
}
.book_btn{
  width:100%;
  /* padding: 1em 3em; */
}
.mobile_sections{
margin-bottom: 20px;
}
}



@media screen and (min-width: 992px) {
 

  .card-details_mobile{
    height:565px;
    
  }
  .book_btn{
    width:100%;
    /* padding: 1em 3em; */
  }
  .search-container .form-group{
    margin-bottom: 0;
    /* margin-top: 15px; */
  }
}


@media screen and (min-width: 767px) {
  .navbar-light .navbar-toggler{
    text-align: center;
  }
  .logo{
    margin-left: 40px;
    height: 70px;
  }
  .subscribe-message{
    color: #fff;
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 20px;
  
  }
  .bookBtn-wrapper1
  {
   width:100%;
  }
  .navb-padding{
    margin-right:0px;
  }
   .inner-menu{
    position: absolute;
    right: 15px;
    top: -5px;
    left: 90%;
   }
   .carousel-height2{
    height:auto;
   }
}

.MuiFormControl-root{
width:100%;
}

label + .MuiInput-formControl {
  margin-top: 20px;
}
.section-margin{
  margin-top:50px;
  margin-bottom: 50px
}
.row-margin-home{
  margin-top:25px
}
.register{
  margin: auto;
  
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  /* display: inline-block; */
  align-self: center;
  display: block;
    align-items: center;
    justify-content: center;
  
}
.register-section{
  
    background-image: url('../Images/register.jpg');
    background-position: center;
    background-size:cover;
    background-repeat: no-repeat;
    /* height: 100vh; */
    /* height:100% !important; */
    background-attachment: fixed;
    
    height: 130vh;
    display: flex;
    align-items: center;
    justify-content: center;
  
}
.register-section-login{
  
  background-image: url('../Images/register.jpg');
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat;
  /* height: 100vh; */
  /* height:100% !important; */
  background-attachment: fixed;
  
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

}
.btn-group{
 background-color: transparent;
}
.dropdown-menu-lg{
  text-align: center;
  border:none;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(38, 38, 38, 0.2);
}

@media (min-width:1400px) {
  .container
  {
   max-width:85%;
  }
 }
 @media (min-width:990px) {
  .bookBtn-wrapper1
  {
   width:100%;
  }
  .navb-padding {
    margin-right: 0px;
}
 }

 @media screen and (min-width: 995px) and (max-width:1700px) {
   .see_all_link{
    font-size: 1.2rem;
    position: absolute;
    right: 30px;
    top: 1px;
    text-decoration: none;
   }
  .logo{
    margin-left:40px;
    height:70px;
  }
   .mr_15{
  margin-right:15px;

}
  .profileImage_mobile {
    margin-top: 0px;
}
.btn_mobile{
  margin-bottom:0px;
}
  .carousel-height2{
   height:280px;
  }
  .navb-padding{
    margin-right:40px;
  }
  .subscribe-message{
    color: #fff;
    position: absolute;
    top: 47px;
    bottom: 0;
    left: 333px;
  
  }
}
 @media screen and (min-width: 1199px) and (max-width:1399px) {
   .search-container .searchcols{
    max-width: 83%;
   }
 
 }


@media (min-width:2000px) {
  .container
  {
   max-width: 1800px;;
  }
  .carousel-height2{
    height:280px;
   }
 }
input[type=number] {
  -moz-appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

.event-row-pd{
  margin-bottom: 25px;
}

.all-events-btn{
  background-size: 200% auto;
    border-radius: 6px;
    padding: 1em 2em;
    /* background-image: linear-gradient(to right, #fdd72a 0%, #fc9d2e 50%, #fc9d2e 100%); */
    background: transparent;
    color: #000;
    border-width: 2px;
    font-weight: 400;
    border-color:#fc9d2e;
    box-shadow:none;
}
/* .all-events-btn:active{
  background-color: #fc9d2e ;
  border-color:#fc9d2e;
} */
/* .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  outline: unset;
  border: unset;
  opacity: 1.1;
  background-color: var(--gray-dark);
} */
.btn-primary:not(:disabled):not(.disabled):active{
  background-color: #fc9d2e ;
  border-color:#fc9d2e;
}
.all-events-btn:focus{

   background-color: #fc9d2e ;
   border-color:#fc9d2e;
 }
 .all-events-btn:active{
  
   background-color: #fc9d2e;
   border-color:#fc9d2e ;
 }

.all-events-btn:hover{

  background-color: #fc9d2e ;
  border-color:#fc9d2e;
}

.all-events-btn1{
  background-size: 200% auto;
    border-radius: 6px;
    padding: 1em 2em;
    width:100%;
    /* background-image: linear-gradient(to right, #fdd72a 0%, #fc9d2e 50%, #fc9d2e 100%); */
    background: transparent;
    color: #000;
    border: 1px solid var(--themedark);
    box-shadow:none;
}
.all-events-btn1:hover{
  /* background-image: linear-gradient(to right, #fdd72a 0%, #fc9d2e 50%, #fc9d2e 100%); */
  background-color: #fc9c2ef3;
  border: unset;
  color:#fff;
}
.all-events-btn1:focus{
  background: transparent;
  color: #000;
  border: 1px solid var(--themedark);

}

.all-events-btn2{
  background-size: 200% auto;
    border-radius: 6px;
    padding: 1em 2em;
  
    /* background-image: linear-gradient(to right, #fdd72a 0%, #fc9d2e 50%, #fc9d2e 100%); */
    background: transparent;
    color: #000;
    border: 1px solid var(--bborder);
    box-shadow:none;
}
.all-events-btn2:hover{
  background-image: linear-gradient(to right, #fdd72a 0%, #fc9d2e 50%, #fc9d2e 100%);
  background-color: unset;
  border: unset;
}


.btn-primary:hover{
  /* color: #fff; */
  
}
/* .btn-primary:focus{
 
  background-color:  #fc9d2e;
border-color:  #fc9d2e;
} */
.btn-primary {
  /* color: #fff; */
  
}

.close{
text-align: end;  
}

.footer-con{
  padding-top: 150px;
  margin-bottom: 50px;
}
/* .btn-primary:focus{
  background-color: #fc9d2e;
  border-color: #fc9d2e;

} */
.btnmargin{
  margin-top:20px;
}

.accCard:hover {
  /* box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.2); */
  /* cursor: pointer;
  transform: scale(1.1);
  */
  transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    /*top: -4px;
    /* border: 1px solid #cccccc; */
    background-color:var(--white );
    cursor: pointer;
 }

 input:focus, textarea{

  outline:none !important
 }

 .position-botton-style{
   position: relative ;
  
 }
 .position-bottom-style-absolute{
   position: absolute;
  margin-bottom:0px;
  
   
 }
 .discount{
   padding-left:0px;
   padding-right: 0px;
 }
 .discount_row{
   margin-top:20px;

   margin-right:15px;
 }
 .srch-btn{
   margin-top:9px;
 }
 .clearDate{
   font-size:0.87rem;
   text-decoration:none;
   cursor:pointer;
   color:var(--lightGrey)
   
 }
 .clearDate:hover{
   text-decoration: none;
   color:var(--black)
 }
 .carousel-height1{
  height:280px;
 }
 .details-font{
  text-transform: capitalize;
  font-weight: bold;
  font-size: 18px
 }
 .img_border_hover:hover{
   border:2px solid var(--themedark);
 }
 .sucess-tags{
  color: var(--white);
 
  background-color: green;
  padding: 10px;
  border-radius: 12px;
  font-size: .875rem;
  margin-bottom:10px;
 }

 .pending-tags{
  color: var(--white);
 
  background-color: #fdd72a;
  padding: 10px;
  border-radius: 12px;
  font-size: .875rem;
  margin-bottom:10px;
 }
.circle_image{
  width: 100%;
  height: 100%;
  /* margin-top: 40px; */
  border-radius: 50%;
  object-fit: cover;
}
.review_date{
  float: right;
    position: absolute;
    right: 0px;
}
 
 .failed-tags{
  color: var(--white);
 
  background-color: #e9371edb;
  padding: 10px;
  border-radius: 12px;
  font-size: .875rem;
  margin-bottom:10px;
 }
 .inner-menu{
  position: absolute;
  right: 5px;
  top: -5px;

 }
 .discount_price_off{
   display:none;
 }
 .discount_price_off_value{
display: block;
 }
 .event_date_show{
   display:block;

 }
 .event_date_hide{
   display: none;
 }
 .btn-primary:disabled {
  color: black;
  background-color: transparent;
  border-color: #fc9c2ef3;
 }
 .btn-primary:hover{
  background-color: #fc9c2ef3;
  border: unset;
 }
 .MuiPickersToolbarButton-toolbarBtn {
  padding: 0;
  min-width: 16px;
  text-transform: none;
  text-align: center;
  width: 100%;
}
.MuiPickersBasePicker-pickerView {
 
  max-width: 100% !important;
}
.meetingLocation{
  flex-direction: row;
    display: flex;
}
.yourself-style{
  color:var(--textfaintopacity);
}
 a:hover {
  color:var(--textfaintopacity);
  
}
a{
  color:var(--textfaintopacity);
}

.nav-tabs .nav-link {
  color:var(--textfaintopacity);
}


.form-row>.col, .form-row>[class*=col-] {
  padding-right: 15px;
  padding-left: 15px;
}
.position_cls{
  position: absolute;
  right: 10;
}
.EllipsisH{

  position: absolute;
  top: 10px;
  right: 10px;
}

.user_cancellation{
  padding:20px;
}
.bbtn-ggroup {
  position: absolute;
  right: 10px;
  top: 18px;
  display: inline-flex;
  vertical-align: middle;
}
.rm-pointers{
  position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform:none !important;
}
.price2{
  color: #fff;

  font-weight: 600;
  background-color: var(--themedark);
  padding: 10px;
  border-radius: 30px;
  position: absolute;
  right: 20px;
  top: -190px;
}
.payment_status_sucess{
  color:green;
}
.payment_status_failed{
  color:red;
}
.payment_status_pending{
  color:yellow;
}
.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
}

  .check-icon {
      width: 80px;
      height: 80px;
      position: relative;
      border-radius: 50%;
      box-sizing: content-box;
      border: 4px solid #4CAF50;
      
  }
  .check-icon ::before{
    top: 3px;
    left: -2px;
    width: 30px;
    transform-origin: 100% 50%;
    border-radius: 100px 0 0 100px;
  }
  .check-icon::after {
          top: 0;
          left: 30px;
          width: 60px;
          transform-origin: 0 50%;
          border-radius: 0 100px 100px 0;
          animation: rotate-circle 4.25s ease-in;
      }
      
      .check-icon::before  {
          content: '';
          height: 100px;
          position: absolute;
          background: #FFFFFF;
          transform: rotate(-45deg);
      }
      .check-icon::after {
        content: '';
        height: 100px;
        position: absolute;
        background: #FFFFFF;
        transform: rotate(-45deg);
        border:0px !important;
    }


      .icon-line {
          height: 5px;
          background-color: #4CAF50;
          display: block;
          border-radius: 2px;
          position: absolute;
          z-index: 10;
      }
          
          .line-tip {
              top: 46px;
              left: 14px;
              width: 25px;
              transform: rotate(45deg);
              animation: icon-line-tip 0.75s;
          }
          
          .line-long {
              top: 38px;
              right: 8px;
              width: 47px;
              transform: rotate(-45deg);
              animation: icon-line-long 0.75s;
          }
      
      
      .icon-circle {
          top: -4px;
          left: -4px;
          z-index: 10;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          position: absolute;
          box-sizing: content-box;
          border: 4px solid rgba(76, 175, 80, .5);
      }
      
      .icon-fix {
          top: 8px;
          width: 5px;
          left: 26px;
          z-index: 1;
          height: 85px;
          position: absolute;
          transform: rotate(-45deg);
          background-color: #FFFFFF;
      }
  


@keyframes rotate-circle {
  0% {
      transform: rotate(-45deg);
  }
  5% {
      transform: rotate(-45deg);
  }
  12% {
      transform: rotate(-405deg);
  }
  100% {
      transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
      width: 0;
      left: 1px;
      top: 19px;
  }
  54% {
      width: 0;
      left: 1px;
      top: 19px;
  }
  70% {
      width: 50px;
      left: -8px;
      top: 37px;
  }
  84% {
      width: 17px;
      left: 21px;
      top: 48px;
  }
  100% {
      width: 25px;
      left: 14px;
      top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
      width: 0;
      right: 46px;
      top: 54px;
  }
  65% {
      width: 0;
      right: 46px;
      top: 54px;
  }
  84% {
      width: 55px;
      right: 0px;
      top: 35px;
  }
  100% {
      width: 47px;
      right: 8px;
      top: 38px;
  }
}

.book_card_hide{
  display:none
}
.book_card_show{
  display:block;
}
.no-event{
  text-align: center;
  color:var(--themedark);
  margin-top: 116px;

}
.date_delete{
  position: absolute;
    right: 0px;
    top: 24px;
    font-size: 1.4rem;
    cursor: pointer;
}
.accordion h2{
  font-size: 1.2rem;
}
.date_review_style{
  position: absolute;
    right: 0;
    top: 0px;
}
.carousel-inner{
  border-radius: 12px;
}
.kit{
  color: rgba(53,53,53,var(--textfaintopacity));
}

.details-font1{
text-transform:capitalize;
font-weight:bold;
}
.btn_small{
  width: 100px;
  height: 41px;
  background-size: 200% auto;
    border-radius: 6px;
    /* padding: 1em 2em; */
    /* background-image: linear-gradient(to right, #fdd72a 0%, #fc9d2e 50%, #fc9d2e 100%); */
    background: transparent;
    color: #000;
    border-width: 2px;
    font-weight: 400;
    border-color: #fc9d2e;
    box-shadow: none;
}
.btn_small:focus{

  background-color: #fc9d2e ;
  border-color:#fc9d2e;
}
.btn_small:active{
 
  background-color: #fc9d2e;
  border-color:#fc9d2e ;
}

.btn_small:hover{

 background-color: #fc9d2e ;
 border-color:#fc9d2e;
}
.margin-userbooking{
  margin-right: 10px;
}
.inactivebox {
  text-align: center;
  padding: 10px;
  border: 1px solid var(--themeboldlight);
  cursor:pointer;
}
.activebox{
background-color: #FDD72A;
text-align: center;
padding: 10px;
border: 1px solid var(--themeboldlight);
cursor:pointer;
}
.chevronn{
  position: absolute;
    right: 15px;
}

.input-range__slider{
width: 0.7rem;
}
.input-range__slider:active {
    transform: scale(1);
}

.normaltext2 {
color: rgba(53,53,53,var(--textfaintopacity));
    font-size: 0.8rem;
    font-weight: 400;
}

.alinks{
  color:#fcac50;
  
}
.disc li{
  list-style-type: disc;
}
.ul_font li{
  color: rgba(53,53,53,1);
    font-size: .875rem;
}
.verification_mail{
  text-decoration: underline;
    cursor:pointer;
}

.blogcard{
  box-shadow: 8px 8px 16px -6px rgb(221 218 214 / 20%);
}
.login-signup{
color:rgb(130, 134, 138);
text-decoration: none;
}
.login-signup:hover{
  color:#fc9d2e;
  text-decoration: underline;
}
.login_signup_pos{
  position: absolute;
    bottom: 15px;
    right: 0;
    left: 0;
}
.beauty-stars_featured li{
  margin-right: 7px !important;
}
.inactiveColor{
  color:transparent;
  }
  .activeColor{
    color: #f4cc18;
  }
  .signup_form.form-control.is-valid, .was-validated .form-control:valid {
 border-color: #ced4da;
    background-image: none;
  }
  .was-validated .form-control:valid:focus{
    border-color: #ced4da;
  }
  .form-control.is-valid:focus, .was-validated .form-control:valid:focus{
    box-shadow: none;
  }
  .btncommon_all{
        text-align: center;
    text-decoration: none;
    font-weight: 500;
    color: var(--white);
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    border: none;
    padding: initial;
    transition: 0.5s;
    width: 120px;
  }
  /*covid poily*/
  .covidpolicy{
    background-color: rgb(252, 157, 46);
   
    padding: 10px;
   
  }
  .mobile_covidpolicy{
    display: none;
  }

   @media screen and (min-width: 220px) and (max-width:600px) {
.covidpolicy{
    
    display: none
   
  }
  .mobile_covidpolicy{
display: block;
  
   }
  }



/*maintence page code*/
.maintence{
  background-image: url('../Images/maintenance.png');
    background-position: center;
    background-size:cover;
    background-repeat: no-repeat;
    /* height: 100vh; */
    /* height:100% !important; */
    background-attachment: fixed;
    
    height: 130vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound-404 {
  position: relative;
  height: 200px;
  margin: 0px auto 20px;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 150px;
  font-weight: 200;
  margin: 0px;
  color: #211b19;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notfound .notfound-404 h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: 400;
  text-transform: uppercase;
  color: #211b19;
  
  padding: 10px 5px;
  margin: auto;
  display: inline-block;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  top:200px;
}

.notfound a {
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  padding: 13px 23px;
  background: #ff6300;
  font-size: 18px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  color: #ff6300;
  background: #211b19;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 h1 {
    font-size: 148px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 148px;
    margin: 0px auto 10px;
  }
  .notfound .notfound-404 h1 {
    font-size: 86px;
  }
  .notfound .notfound-404 h2 {
    font-size: 16px;
  }
  .notfound a {
    padding: 7px 15px;
    font-size: 14px;
  }
}