
.profileImg {
    position: relative;
    
   
     transition: transform .2s;
     /* width: 100px !important; */
     border: 1px solid;
     border-color: rgb(124, 121, 121);
   }
   .profileImg:hover{
     transform: scale(1.1);
   }
   .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 1px !important;
}
.multi{
  display: -webkit-box;
   -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.Textnooverflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dis:hover {
  background-color: white !important;
  border-color: #fc9c2ef3 !important;
  border-width: 1px;
  border: 1px solid #fc9c2ef3!important;
}
.MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(14px, 20px) scale(1);
  pointer-events: none;
  margin-top: -7px;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 8.5px 4px;
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color:#ffd905e0; */
  z-index: 9999999;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .preloader.hide {
    opacity: 0;
    display: none; }


.loader {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 75px;
  display: inline-block;
  vertical-align: middle; }

.loader-1 .loader-outter {
  position: absolute;
  border: 4px solid #1a0508;
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
  animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite; }

.loader-1 .loader-inner {
  position: absolute;
  border: 4px solid #fc9d2e;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  border-right: 0;
  border-top-color: transparent;
  -webkit-animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
  animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite; }

@-webkit-keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

@keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }
